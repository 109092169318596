import { gql } from 'graphql-request'

export const Q_USERS_PORTAL = gql`
  query UsersPortal {
    usersPortal {
      ... on BillingPortal {
        url
      }

      ... on QueryFailure {
        errors {
          message
        }
      }
    }
  }
`

import { store } from 'quasar/wrappers'
import { createPinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store((/* { ssrContext } */) => {
  const pinia = createPinia()

  // You can add Pinia plugins here
  // pinia.use(SomePiniaPlugin)

  const installPersistedStatePlugin = createPersistedStatePlugin({
      serialize: (data) => {
        //return base58.encode(new Buffer(JSON.stringify(data), 'hex'))
        return btoa(JSON.stringify(data))
      },
      deserialize: (data) => {
        //const decoded = base58.decode(data)
        //return JSON.parse(new Buffer(decoded).toString('hex'))
        return JSON.parse(atob(data))
      }
    }
  )
  pinia.use((context) => installPersistedStatePlugin(context))
  return pinia
})

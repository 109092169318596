import { gql } from 'graphql-request'

export const Q_USER_AFFILIATE = gql`
  query UserAffiliate {
    userAffiliate {
      ... on AffiliateSuccess {
        affiliate {
          id
          link
          visitors
          leads
          conversions
        }
      }

      ... on QueryFailure {
        errors {
          message
        }
      }
    }
  }
`

import {acceptHMRUpdate, defineStore} from 'pinia';
import moment from "moment-timezone";

const ALL_TRADES = 0
const UNUSUAL_TRADES = 1
const WEEKLY_TRADES = 2
const SWING_TRADES = 3
const LEAP_TRADES = 4
const ODTE_TRADES = 5

const TRADE_CALLS = 1
const TRADE_PUTS = 2

const ALL_ORDERS = 0
const SWEEPS_ORDERS = 1
const LARGE_ORDERS = 2
const BLOCK_ORDERS = 3

const getWeeklyExp = (weeksOffset) => {
    let currentDateTime = moment().tz('America/New_York');
    if (weeksOffset !== 0 || currentDateTime.day() !== 6) {
        // if date is not Friday already, skip this logic
        const offset = weeksOffset * 7;
        currentDateTime.add(offset + ((7 + 6 - currentDateTime.day()) % 7), 'days');
    }
    currentDateTime.startOf('day');
    return currentDateTime.toDate();
}

export const useExchangeStore = defineStore('exchange', {
  state: () => ({
    realtimeEnabled: true,
    premiumAmountFilter: 25000,
    symbols: [],
    excludeSymbols: [],
    tradesTypeFilter: ALL_TRADES,
    tradeTypesTimeframeFilter: 0,
    tradeDirection: 0,
    tradeOrderType: ALL_ORDERS,
    contractPrice: null,
    shouldRefreshTrades: false,
    aboveAsk: false,
    createdAfter: null,
    createdBefore: null,
    expirations: null,
  }),
  getters: {
    chatEnabled: (state) => {
      return window.posthog?.isFeatureEnabled("chatgpt") === true || false
    },
    subscribeTradesEnabled: (state) => {
      return window.posthog?.isFeatureEnabled("subscribe_trades") === true || false
    },
    selectedSymbols: (state) => state.symbols,
    unusual: (state) => {
      return state.tradesTypeFilter === UNUSUAL_TRADES
    },
    expireTodayEnabled: (state) => {
      return state.tradeTypesTimeframeFilter === ODTE_TRADES
    },
    minCreated: (state) => {
      if (state.createdAfter) {
        return moment(state.createdAfter, 'YYYY-MM-DD HH:mm').tz('America/New_York').toDate()
      } else if (state.tradeTypesTimeframeFilter === ODTE_TRADES) {
        return moment().tz('America/New_York').startOf("day").toDate();
      }
      return moment().tz('America/New_York').subtract(2, "week").toDate();
    },
    maxCreated: (state) => {
      if (state.createdBefore) {
        return moment(state.createdBefore, 'YYYY-MM-DD HH:mm').tz('America/New_York').toDate()
      }
      return null
    },
    onlyExpirations: (state) => {
      if (state.expirations) {
        return state.expirations.map((exp) => moment(exp, 'YYYY-MM-DD').toDate())
      }
      return null
    },
    minExpiration: (state) => {
      switch(state.tradeTypesTimeframeFilter) {
        case ODTE_TRADES:
          return moment.tz('America/New_York').startOf('day').toDate();
        case WEEKLY_TRADES:
          return moment().tz('America/New_York').startOf('week').toDate();
        case SWING_TRADES:
          return getWeeklyExp(0)
        case LEAP_TRADES:
          return getWeeklyExp(24)
      }
      return null
    },
    maxExpiration: (state) => {
      switch(state.tradeTypesTimeframeFilter) {
        case ODTE_TRADES:
          return moment.tz('America/New_York').endOf('day').toDate();
        case WEEKLY_TRADES:
          return getWeeklyExp(0)
        case SWING_TRADES:
          return getWeeklyExp(24)
      }
      return null
    },
    tradeDirectionTypeBullish: (state) => {
      return state.tradeDirection === TRADE_CALLS
    },
    tradeDirectionTypeBearish: (state) => {
      return state.tradeDirection === TRADE_PUTS
    },
    minContractPrice: (state) => {
      return state.contractPrice
    }
  },
  actions: {

  },
},
{
  persistedState: {
    // store options goes here
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExchangeStore, import.meta.hot))
}

import {useUserStore} from '../stores/user-store';
import {loggerFactory} from '../logger'

const logger = loggerFactory("Router")
const requireAuth = async (to, from, next) => {
  logger.debug("Checking auth for route", from, to)
  return useUserStore()
    .currentUser()
    .then((user) => {
      if (user) {
        logger.debug("User validated...continue", user);
        next();
      } else {
        logger.debug("User not validated...login");
        next({ path: "/auth/login", query: { nextUrl: to.path } });
      }
    })
    .catch((e) => {
      logger.error("Failure processing current user", e)
      next({ path: "/auth/login", query: { nextUrl: to.path } });
    });
};

const routes = [
  {
    path: '/home',

    component: () => import('layouts/HomeLayout.vue'),
    children: [
      {path: '', component: () => import('pages/HomePage.vue')}
    ]
  },
  {
    path: '/auth',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {path: 'login', component: () => import('pages/Auth/LoginPage.vue'), alias: ['/login-forgot']},
      {
        path: 'register/:referral?',
        component: () => import('pages/Auth/RegisterPage.vue'),
        alias: ['/signup/:referral?', '/get-started/:referral?']
      },
      {path: 'forgot', component: () => import('pages/Auth/ForgotPage.vue')},
      {path: 'confirm', component: () => import('pages/Auth/ConfirmPage.vue')},
      {path: 'retrieve-checkout-link', component: () => import('pages/Auth/RetrieveCheckoutLinkPage.vue')}
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ExchangePage.vue'),
        beforeEnter: requireAuth,
        meta: {loading: true},
        alias: ['/exchange'],
      },
      {
        path: 'top-contracts',
        component: () => import('pages/TopContractsPage.vue'),
        beforeEnter: requireAuth,
        meta: { loading: true}
      },
      {
        path: 'alerts',
        component: () => import('pages/AlertsPage.vue'),
        beforeEnter: requireAuth,
        meta: {loading: true}
      }
    ]
  },
  {
    path: '/start',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {path: '', component: () => import('pages/StartPage.vue'), beforeEnter: requireAuth},
      {path: 'reactivate/:promotion', component: () => import('pages/ReactivatePage.vue'), beforeEnter: requireAuth}
    ]
  },

  {
    path: '/subscribed',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {path: '', component: () => import('pages/SubscribedPage.vue'), beforeEnter: requireAuth}
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    redirect: "/",
  }
]

export default routes

import { gql } from 'graphql-request'

export const M_SETUP_BILLING = gql`
  mutation SetupBilling($token: String!) {
    usersSetupBilling(jwt: $token) {
      ... on BillingSuccess {
        subscribed
      }

      ... on MutationFailure {
        errors {
          message
        }
      }
    }
  }
`

import 'ulog'
import anylogger from 'anylogger'
const logger = anylogger('CapitalFlow')

logger.level = import.meta.env.VITE_APP_LOG_LEVEL
const loggerFactory = (name) => {
  const localizedLogger = anylogger(name)
  localizedLogger.level = import.meta.env.VITE_APP_LOG_LEVEL
  return localizedLogger
}

export { logger, loggerFactory }

/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {QSpinnerDots,Notify,Loading,Dialog} from 'quasar'



const userOptions = { config: {"notify":{},"loading":{"spinnerColor":"secondary","spinner":"QSpinnerDots","spinnerSize":50}},components: {QSpinnerDots},plugins: {Notify,Loading,Dialog} }
  
userOptions.config.loading.spinner = QSpinnerDots
  
  
export default userOptions


<template>
  <div v-if="loading" class="spinner-container">
    <q-spinner-dots size="40px" color="secondary" />
  </div>
  <router-view v-else />
</template>

<script>
import { onMounted, onUnmounted, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from "quasar"
import { logger } from './logger'
import { useUserStore } from "stores/user-store"
import { APP_VERSION } from './version';
import { useExchangeStore } from "stores/exchange-store.js";
import { isExchangeHours } from "src/utils";
import { storeToRefs } from "pinia";
import { referralInit, page } from "./rum";

export default defineComponent({
  name: 'App',
  setup() {
    const $q = useQuasar()
    const router = useRouter()
    const userStore = useUserStore()
    const exchangeStore = useExchangeStore()
    const loading = ref(true)

    const {
      realtimeEnabled,
    } = storeToRefs(exchangeStore)

    exchangeStore.$subscribe((mutation, state) => {
      logger.debug("State change:", mutation.type, mutation)
      if (mutation.events?.key) {
        logger.debug(`State change ${mutation.events.key} ${mutation.events.oldValue} -> ${mutation.events.newValue}`)
      }
    })

    router.beforeEach((to, from, next) => {
      if(to.meta?.loading) {
        $q.loading.show()
      }
      next()
    })

    router.beforeResolve((to, from, next) => {
      $q.loading.hide()
      next()
    })

    window.posthog?.onFeatureFlags(function() {
      logger.debug("posthog.onFeatureFlags loaded")
    })

    const intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "nytplqib",
    };

    let authStateSubscription, marketWatchInterval
    onMounted(() => {
      logger.debug("Loaded app with version", APP_VERSION)
      page()
      referralInit()

      authStateSubscription = userStore.authState((event, session) => {
        logger.debug("Session did change", event)
        loading.value = false
        if(event === "SIGNED_OUT") {
          router?.replace({ path: "/auth/login?msg=timeout" })
        }

        if (import.meta.env.PROD) {
          const bootSettings = {...intercomSettings}
          if (session?.user) {
            bootSettings.user_id = session.user.id;
            bootSettings.email = session.user.email;
            bootSettings.name = session.user.user_metadata?.first_name || session.user.user_metadata?.full_name;
            if (session.user.user_metadata?.phone) {
              bootSettings.phone = session.user.user_metadata.phone;
            }
          }
          window.Intercom?.("boot", bootSettings);
        }
      })

      document.addEventListener("visibilitychange", () => {
        logger.debug("Visibility change", document.hidden)
        if (document.hidden) {
          exchangeStore.$patch({ realtimeEnabled: false })
        } else {
          exchangeStore.$patch({ shouldRefresh: true  })
        }
      });

      if(import.meta.env.PROD) {
        setInterval(async () => {
          try {
            const response = await fetch('/version.json');
            const data = await response.json();
            logger.debug("Checking versions", data.version, APP_VERSION)
            if (response.ok && data.version !== APP_VERSION) {
              // New version available
              window.location.reload(); // Force reload
            } else {
              logger.debug("No new version available")
            }
          } catch (error) {
            logger.error("Error fetching version:", error);
          }
        }, 60000); // Check every 60 seconds, adjust as needed
      }

      marketWatchInterval = setInterval(() => {
        if(realtimeEnabled.value) {
          if(!isExchangeHours()) {
            logger.debug("Outside exchange hours")
            exchangeStore.$patch({ realtimeEnabled: false })
          }
        }
      }, 60000)
    })

    onUnmounted(() => {
      authStateSubscription?.data?.subscription?.unsubscribe()
      if (marketWatchInterval) clearInterval(marketWatchInterval)
    })

    return {
      loading
    }
  }
})
</script>
<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>

import { gql } from 'graphql-request'

export const Q_USERS_CHECKOUT = gql`
  query UsersCheckout($input: BillingCheckoutInput) {
    usersCheckout(input: $input) {
      ... on BillingCheckout {
        checkoutUrl
      }

      ... on QueryFailure {
        errors {
          message
        }
      }
    }
  }
`

export const Q_USER_CHECKOUT_LINK = gql`
  query UserCheckoutLink($email: String, $coupon: String, $promotion: String) {
    userCheckoutLink(email: $email, coupon: $coupon, promotion: $promotion) {
      ... on BillingCheckout {
        checkoutUrl
      }

      ... on QueryFailure {
        errors {
          message
        }
      }
    }
  }
`

import {defineStore} from 'pinia';
import {
  Q_USERS_PORTAL,
  Q_USERS_CHECKOUT,
  Q_USER_CHECKOUT_LINK,
  Q_USER_AFFILIATE,
  M_SETUP_BILLING,
} from "../services/graphql"
import {base_api_url} from '../constants'
import { gql } from 'graphql-request'
import {request, GraphQLClient } from 'graphql-request'
import {loggerFactory} from "../logger"
import {setUser, captureError, captureProblem, track} from "src/rum"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {getClient} from "../supabase"
import moment from 'moment-timezone'

const isProduction = process.env.NODE_ENV === 'production'
const http = isProduction ? 'https' : 'http'
const apiURL = `${http}://${base_api_url}`
const logger = loggerFactory("UserStore")

const retrieveMessageByErrorCode = (code, namespace = '') => {
  switch (code) {
    case 'auth/email-already-in-use':
      return "Looks like you might want to try logging in first."
    case 'auth/user-disabled':
      return "Your account has been disabled, please reach out to get further support."
    case 'auth/invalid-password':
    case 'auth/wrong-password':
    case 400:
      return "Invalid email or password provided, please correct and try again."
    case 'auth/too-many-requests':
      return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
    case 'auth/weak-password':
      return 'The password entered is too weak. Please input complex password  with minimum 6 characters.'
    default:
      return 'We were unable to process your request at the time.'
  }
}

export const useUserStore = defineStore('session', {
  state: () => ({
    userId: null,
    profileId: null,
    loadingUser: false,
    loading: false,
    fingerprint: null,
    subscribed: false,
    pastDue: false,
  }),
  getters: {
    isSubscribed: (state) => state.subscribed,
  },
  actions: {
    authState(callback) {
      logger.debug("Initializing auth state listener...")
      const auth = getClient().auth
      return auth.onAuthStateChange((event, session) => {
        logger.debug("onAuthStateChange", event, session);
        if (event === "SIGNED_OUT") {
          this.userId = null
        }

        setTimeout(async () => {
          if (event === "SIGNED_OUT") {
            setUser(null)
          }

          if (session?.user) {
            this.userId = session.user.id;
            setUser(session.user.id, {
              email: session.user.email,
              first_name: session.user.user_metadata?.first_name,
              last_name: session.user.user_metadata?.last_name
            })
          }

          try { callback?.(event, session); } catch (e) {}
        }, 0)
      });
    },
    async currentUser() {
      const auth = getClient().auth;
      // https://supabase.com/docs/reference/javascript/auth-getsession
      // https://supabase.com/docs/reference/javascript/auth-getuser
      const {
        data: { user },
      } = await auth.getUser();

      if (user) {
        this.userId = user.id
        setTimeout(async () => {
          setUser(user.id, {
            email: user.email,
            first_name: user.user_metadata?.first_name,
            last_name: user.user_metadata?.last_name
          })
        }, 0)
      }

      return user;
    },
    async sessionToken() {
      const { data, error } = await getClient().auth.getSession()
      if (error) throw error;

      if (data.session) {
        return data.session.access_token
      }
    },
    async session() {
      const client = getClient()
      const auth = client.auth
      const { data, error } = await auth.getSession()
      if (data?.session) {
        logger.debug("Received users session", data.session)
        const user = data.session.user
        const token = data.session.access_token
        let fName = data.session.user.user_metadata?.first_name
        let lName = data.session.user.user_metadata?.last_name

        const userResult = await client.from("users")
            .select("*")
            .or(`id.eq.${user.id},user_id.eq.${user.id}`)
            .limit(1)
            .single()
        if (userResult.error) {
          logger.debug("Error loading user", userResult.error)
          captureError(userResult.error)
          throw new Error("Unable to load your session.")
        }

        logger.debug("Retrieved profile", userResult)
        this.profileId = userResult.data?.id
        fName = fName ?? userResult.data?.first_name
        lName = lName ?? userResult.data?.last_name
        setUser(user.id, {
          email: data.session.user.email,
          first_name: fName,
          last_name: lName,
          name: `${fName} ${lName}`
        })
        // user is subscribed if they have a subscription id && not cancelled
        this.subscribed = !!userResult.data?.subscription &&
          (!userResult.data?.subscription_cancelled ||
            moment(userResult.data?.subscription_cancelled).isAfter(moment()));
        this.pastDue = userResult.data?.past_due || false
        await this.submitAuditAccess()

        return {id: user.id, subscribed: this.subscribed, pastDue: this.pastDue}
      } else {
        return Promise.resolve(null)
      }
    },
    async billingPortal() {
      const auth = getClient().auth
      const { data, error } = await auth.getSession()
      if (data.session) {
        const access_token = data.session.access_token
        const graphQLClient = new GraphQLClient(apiURL, {
          credentials: `include`,
          mode: `cors`,
          headers: {
            authorization: `Bearer ${access_token}`
          }
        })
        return graphQLClient.request(Q_USERS_PORTAL, {})
          .then((result) => {
            return result?.usersPortal
          })
      } else {
        return Promise.resolve(null)
      }
    },
    async signup(username, password, phone, firstName, lastName) {
      logger.debug(`Additional attributes ${phone} ${firstName} ${lastName}`)
      const client = getClient()
      const auth = client.auth
      const self = this
      const signupRequest = {
        email: username,
        password: password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
            phone: phone
          }
        }
      }
      return auth.signUp(signupRequest).then((response) => {
        if (response.error) throw response.error;
        const credential = response.data
        logger.debug('signUp', credential)
        const user = credential.user;
        self.userId = user.id;

        // create metadata row
        const now = new Date().toISOString()
        return client.from("users")
            .insert({
              id: user.id,
              user_id: user.id,
              email: username,
              phone: phone,
              first_name: firstName,
              last_name: lastName,
              logins: 1,
              last_login: now,
              created:now,
            })
            .select("id,email")
      }).catch(error => {
        logger.error('signUp', error);
        // track error
        captureProblem(
          `Signup failure - ${error?.name}: ${error.message}`,
          {email: username, ...error, json: error?.toJSON?.()}
        )
        let errorMessage = retrieveMessageByErrorCode(error.status, error.name)
        if (error.message) errorMessage += ` ${error.message}`

        // special case for user already registered
        if (error.status === 422 || error.message === "User already registered") {
          errorMessage = "Submission failed, you may have an existing account or need to correct your details."
        }
        throw new Error(errorMessage)
      });
    },
    async signIn(username, password) {
      const auth = getClient().auth

      // login to supabase
      const singInResult = await auth.signInWithPassword({email: username, password: password})
      if (singInResult.error) {
        logger.error("Encountered error processing login", singInResult.error)
        logger.error('signInWithPassword', JSON.stringify(singInResult.error));
        captureProblem(
          `Login failure - ${singInResult?.error?.name}: ${singInResult.error.message}`, {
          email: username, ...singInResult.error, json: singInResult.error?.toJSON?.()
        })
        let errorMessage = retrieveMessageByErrorCode(singInResult.error.status, singInResult.error.name)
        if (singInResult.error.message) errorMessage += ` ${singInResult.error.message}`

        throw new Error(errorMessage)
      }

      const credential = singInResult.data;
      logger.debug('signInWithPassword', credential)

      // Signed in
      return credential.user
    },
    async passwordReset(email) {
      const auth = getClient().auth
      return auth.resetPasswordForEmail(email, {redirectTo: `${window.location.origin}/auth/confirm`})
        .then((response) => {
          if (response.error) throw response.error
          logger.debug('resetPasswordForEmail', response.data);
        })
        .catch((error) => {
          logger.error('resetPasswordForEmail', JSON.stringify(error));
          const errorMessage = retrieveMessageByErrorCode(error.code, error.name)
          throw new Error(errorMessage)
        });
    },
    async signUserOut() {
      const auth = getClient().auth
      return auth.signOut()
        .then(() => {
          logger.debug('signOut');
          this.userId = null
          setUser(null)
          return true
        }).catch((error) => {
          logger.error('signOut', error);
          const errorMessage = retrieveMessageByErrorCode(error.code, error.name)
          throw new Error(errorMessage)
        });
    },
    async changePassword(code, password) {
      const auth = getClient().auth
      return auth.updateUser({password: password})
        .then(() => {
          logger.debug('updateUser');
        }).catch((error) => {
          logger.error('updateUser', error)
          const errorMessage = retrieveMessageByErrorCode(error.code, error.name)
          throw new Error(errorMessage)
        })
    },
    async setupBilling(jwt) {
      logger.debug("setupBilling initializing")
      const auth = getClient().auth
      const { data, error } = await auth.getSession()
      if (data.session) {
        const token = data.session.access_token
        const graphQLClient = new GraphQLClient(apiURL, {
          credentials: `include`,
          mode: `cors`,
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        return graphQLClient.request(M_SETUP_BILLING, {token: jwt})
          .then((result) => {
            logger.debug('setupBilling => result', result)
            if (result.errors || result.usersSetupBilling.errors) {
              throw new Error(result?.usersSetupBilling?.errors?.[0]?.message ?? "Unable to load your billing session.")
            }
            return result.usersSetupBilling.subscribed
          })
      } else {
        return Promise.resolve(null)
      }
    },
    async retrieveCheckout(referralCode, annual = false) {
      let userReferralCode = null
      if (referralCode) userReferralCode = referralCode
      const auth = getClient().auth
      const { data, error } = await auth.getSession()
      if (data.session) {
        const token = data.session.access_token
        const graphQLClient = new GraphQLClient(apiURL, {
          credentials: `include`,
          mode: `cors`,
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        return graphQLClient.request(Q_USERS_CHECKOUT, {
          input: {
            annual: annual,
            referralCode: userReferralCode,
            redirect: `${window.location.origin}/subscribed`,
            redirectCancel: `${window.location.origin}/start`
          }
        })
          .then((result) => {
            logger.debug('retrieveCheckout => result', result)
            if (result.errors || result.usersCheckout.errors) {
              throw new Error(result?.usersCheckout?.errors?.[0]?.message ?? "Unable to load your checkout session.")
            }
            return result.usersCheckout?.checkoutUrl
          })
      } else {
        return Promise.resolve(null)
      }
    },
    async retrieveCheckoutLink(email, coupon = null, promotion = null) {
      const graphQLClient = new GraphQLClient(apiURL, {
        credentials: `include`,
        mode: `cors`,
      })
      return graphQLClient.request(Q_USER_CHECKOUT_LINK, {email, coupon, promotion})
        .then((result) => {
          logger.debug('retrieveCheckout => result', result)
          if (result.errors || result.userCheckoutLink.errors) {
            throw new Error(result?.userCheckoutLink?.errors?.[0]?.message ?? "Unable to load your checkout session.")
          }
          return result.userCheckoutLink?.checkoutUrl
        })
    },
    async submitAuditAccess() {
      const self = this
      const client = getClient()
      const auth = client.auth
      const fingerprint = this.fingerprint

      async function getFingerprint() {
        try {
          if (fingerprint) {
            return Promise.resolve({ visitorId: fingerprint });
          }
          const fpInstance = await FingerprintJS.load({ monitoring: false });
          return fpInstance.get();
        } catch (error) {
          logger.error('Error getting fingerprint:', error);
          return null;
        }
      }

      async function getIPAddress(retryCount = 3) {
        let attempts = 0;

        while (attempts < retryCount) {
          try {
            const response = await fetch("https://api.ipify.org?format=json");

            // Ensure the response is OK before proceeding
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();
          } catch (error) {
            attempts++;
            logger.error(`Attempt ${attempts} - Error getting IP address:`, error);

            // If this was the last attempt, return null
            if (attempts === retryCount) {
              return null;
            }
          }
        }
      }

      async function getSession() {
        try {
          const client = getClient();
          const auth = client.auth;
          return auth.getSession();
        } catch (error) {
          logger.error('Error getting session:', error);
          return null;
        }
      }

      async function logAuditAccess(fingerprint, ip, session) {
        try {
          const client = getClient();
          const payload = {
            id: crypto.randomUUID(),
            created: new Date().toISOString(),
            user_id: session.user.id,
            fingerprint,
            ip_address: ip
          };

          const result = await client.from("access_audit")
            .insert(payload)
            .select("*");

          logger.debug('submitAuditAccess => result', result);
        } catch (error) {
          logger.error('Error logging audit access:', error);
        }
      }

      try {
        const [
          fingerprintResult,
          ipResult,
          sessionResult
        ] = await Promise.all([
          getFingerprint(),
          getIPAddress(),
          getSession()
        ]);
        this.fingerprint = fingerprintResult?.visitorId;
        if (sessionResult?.data?.session?.user
          && ipResult?.ip
          && sessionResult?.data?.session?.access_token) {
          await logAuditAccess(fingerprintResult?.visitorId, ipResult?.ip, sessionResult.data.session);
        }
      } catch (error) {
        logger.error('Error in submitAuditAccess:', error);
      }
    },

    async requestAffiliateData() {
      const auth = getClient().auth
      const { data, error } = await auth.getSession()
      const token = data.session.access_token

      const graphQLClient = new GraphQLClient(apiURL, {
        credentials: `include`,
        mode: `cors`,
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      return graphQLClient.request(Q_USER_AFFILIATE, {})
        .then((result) => {
          logger.debug('requestAffiliateData => result', result)
          if (result.errors || result.userAffiliate.errors) {
            throw new Error(result?.userAffiliate?.errors?.[0]?.message ?? "Unable to load your affiliate data.")
          }
          return result?.userAffiliate
        })
    },

    async fetchWatchlists() {
      const client = getClient();
      try {
        const result = await client.from("watchlists")
          .select("*,watchlist_items(*)")
          .eq("user_id", this.profileId)
        logger.debug("fetchWatchlists => result", result)
        return result?.data
      } catch (err) {
        logger.error("Error fetching watchlists...", err);
        throw err
      }
    },

    async createWatchlist(name) {
      const client = getClient();
      try {
        const result = await client.from("watchlists")
          .insert({ name: name, user_id: this.profileId})
          .select()
        logger.debug("createWatchlist => result", result)
        return result?.data?.[0]
      } catch (err) {
        logger.error("Error creating watchlist...", err);
        throw err
      }
    },

    async addWatchlistItem(id, item) {
      const client = getClient();
      try {
        const result = await client.from("watchlist_items")
          .insert({ watchlist_id: id, stock_symbol: item})
          .select().single()
        logger.debug("createWatchlistItem => result", result)
        return result?.data
      } catch (err) {
        logger.error("Error adding watchlist item...", err);
        throw err
      }
    },

    async removeWatchlistItem(id) {
      const client = getClient();
      try {
        const result = await client.from("watchlist_items")
          .delete()
          .eq("id", id)
          .select().single()
        logger.debug("createWatchlistItem => result", result)
        return result?.data
      } catch (err) {
        logger.error("Error adding watchlist item...", err);
        throw err
      }
    }
  }
}, {
  persistedState: {
    // store options goes here
    excludePaths: []
  },
})

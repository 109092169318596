import { createClient } from "@supabase/supabase-js";

const SUPABASE_PROJECT_URL = import.meta.env.VITE_SUPABASE_PROJECT_URL;
const SUPABASE_PROJECT_REPLICA_URL = import.meta.env.VITE_SUPABASE_PROJECT_REPLICA_URL;
const SUPABASE_PUBLIC_ANON_API_KEY = import.meta.env
  .VITE_SUPABASE_PUBLIC_ANON_API_KEY;

const authOptions = {
  storageKey: `${import.meta.env.MODE}-cp-sb.auth.token`,
};

const client = createClient(
  SUPABASE_PROJECT_URL,
  SUPABASE_PUBLIC_ANON_API_KEY,
  {
    auth: authOptions,
    global: {

    }
  }
);

const readonlyClient = createClient(
  SUPABASE_PROJECT_REPLICA_URL,
  SUPABASE_PUBLIC_ANON_API_KEY,
  {
    auth: authOptions,
    global: {

    }
  }
);

const getClient = (readonly = false) => {
  return readonly ? readonlyClient : client
}

const buildClient = (headers = {}, readonly = false) => {
  const options = {
    auth: authOptions,
    global: {
      headers: headers,
    },
    // realtime: {
    //   params: {
    //     eventsPerSecond: 1,
    //   },
    // },
  }
  return createClient(
    readonly ? SUPABASE_PROJECT_REPLICA_URL : SUPABASE_PROJECT_URL,
    SUPABASE_PUBLIC_ANON_API_KEY,
    options
  );
}

export { client, getClient, buildClient };

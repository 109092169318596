import * as Sentry from "@sentry/browser";
import _throttle from 'lodash/throttle';
import { APP_VERSION } from './version';
import { logger } from "./logger";

const superPropertyKeys = {
  version: "_cf_v"
}

const superProperties = {
  version: localStorage.getItem(superPropertyKeys.version)
}

if(import.meta.env.PROD) {
  window.posthog?.register({
    "app_version": APP_VERSION,
  })
}

const setCannyUser = _throttle((user) => {
  if (window.Canny && user?.name) {
    Canny('identify', {
      appID: '65d2dd49384f842296a38d40',
      user
    });
  }
}, 30 * 1000)


export const setUser = (id, traits = {}) => {
  logger.debug("Identify", id, traits)
  const analytics = window.segmentAnalytics

  if(id) {
    analytics?.identify(id, {
      ...traits,
      firstName: traits?.first_name,
      lastName: traits?.lastName
    })
  } else {
    analytics?.reset()
  }

  if(!import.meta.env.PROD) return
  if(id) {
    Sentry.setUser({ id, email: traits?.email });

    window.posthog?.identify(id, traits);

    if (window.klaviyo && traits?.email) {
      window.klaviyo.identify({
        email: traits.email,
        id,
        'first_name' : traits?.first_name,
        'last_name' : traits?.last_name,
      });
    }

    setCannyUser({
      email: traits?.email,
      name: traits?.name || traits?.first_name,
      id: id
    })
  } else {
    Sentry.setUser(null)
    window.posthog?.reset()
  }
}

export const setUserTrait = (key, value) => {
  const analytics = window.segmentAnalytics
  if (typeof analytics === 'undefined') return
  const traits = {}
  traits[key] = value
  analytics.identify(traits)
}

export const setUserTraits = (traits) => {
  const analytics = window.segmentAnalytics
  if (typeof analytics === 'undefined') return
  analytics.identify(traits)
}

export const setSuperProperty = (key, value) => {
  if(Object.keys(superPropertyKeys).includes(key)) {
    superProperties[key] = value
    localStorage.setItem(superPropertyKeys[key], value)
  }
}

function _formatSupabaseError(error) {
  let message = error.message;
  if (error.details) message += ` | Details: ${error.details}`;
  if (error.hint) message += ` | Hint: ${error.hint}`;
  if (error.code) message += ` | Code: ${error.code}`;
  return new Error(message);
}

export const captureError = (err) => {
  if(!import.meta.env.PROD) return
  // check if PostgresError
  Sentry.captureException(_formatSupabaseError(err))
}

export const captureProblem = (msg, context) => {
  if(!import.meta.env.PROD) return
  Sentry.withScope(function (scope) {
    scope.setLevel("error");
    scope.setExtras(context)
    Sentry.captureMessage(msg);
  });
}

export const track = (event, ctx = {}) => {
  logger.debug("Track event", event, ctx)
  const analytics = window.segmentAnalytics
  const storedProperties = {}
  Object.keys(superPropertyKeys).forEach(function(key) {
    const storedProperty = superProperties[key]
    if (storedProperty) {
      storedProperties[key] = storedProperty
    }
  })
  const mergedProperties = { ...ctx, ...storedProperties }
  analytics?.track(event, mergedProperties)

  if(!import.meta.env.PROD) return
  window?.Intercom?.('trackEvent', event, ctx)
  window.posthog?.capture?.(event, ctx)
  window.klaviyo?.push?.(['track', event, ctx])
}

export const page = (name) => {
  logger.debug("Page event", name)
  const analytics = window.segmentAnalytics
  analytics?.page(name)
}

export const referralInit = () => {
  if(!import.meta.env.PROD) return
  if (window.rewardful) {
    window.rewardful('ready', function () {
      if (Rewardful?.referral) {
        logger.debug('Current referral ID: ', Rewardful.referral);
      } else {
        logger.debug('No referral present.');
      }
    });
  }
}

export const referralTrackLead = (id) => {
  if(!import.meta.env.PROD) return
}

export const referralTrackConversion = (id) => {
  if(!import.meta.env.PROD) return
}

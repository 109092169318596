import moment from "moment-timezone";
import { logger } from "../logger";

const timeFormat = 'HH:mm:ss'
const beforeTime = moment.tz('7:30:00', timeFormat, "America/New_York"),
  afterTime = moment.tz('16:30:00', timeFormat, "America/New_York");

const isExchangeHours = () => {
  const time = moment().tz("America/New_York")
  logger.debug("Exchange hours now", time.format())
  logger.debug("Exchange hours open", beforeTime.format())
  logger.debug("Exchange hours close", afterTime.format())
  const dayOfWeek = time.day();
  const isWeekDay = dayOfWeek >= 1 && dayOfWeek <= 5
  return isWeekDay && time.isBetween(beforeTime, afterTime)
}

const isExchangeDay = () => {
  const dayOfWeek = moment().tz("America/New_York").day();
  return dayOfWeek >= 1 && dayOfWeek <= 5
}

const marketDay = () => {
  const time = moment().tz("America/New_York")
  const currentHour = time.hour();
  if (currentHour >= 0 && currentHour < 7) {
    return time.subtract(1, 'days').dayOfYear();
  } else if (time.isoWeekday() >= 6) { // 6 and 7 corresponds to Saturday and Sunday
    return time.isoWeekday(5).dayOfYear(); // Returns the latest Friday
  } else {
    return time.dayOfYear()
  }
}

export {
  isExchangeHours,
  isExchangeDay,
  marketDay
}
